const Functions = {
  linkAvatar: (fname, lname) => {
    const stringToColor = require('string-to-color')
    const background = stringToColor(fname+" "+lname).slice(1)
    const r = parseInt(background[0], 16)*16+parseInt(background[1], 16)*1
    const g = parseInt(background[2], 16)*16+parseInt(background[3], 16)*1
    const b = parseInt(background[4], 16)*16+parseInt(background[5], 16)*1
    const color = ((r*1+g*1+b*1)/3*2 > 300) ? "000" : "FFF"
    return 'https://eu.ui-avatars.com/api/?bold=true&background='+background+'&color='+color+'&name='+fname+'+'+lname
  }
}

export default Functions