import { Button, Dialog, DialogActions, DialogContent,  DialogTitle, Grid, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"

const RenameList = (props) => {
  useEffect(() => {
    setName(props.name)
  }, [props])

  const [name, setName] = useState(props.name)

  const confirm = () => {
    props.handleConfirm(name)
    setName("")
    props.handleCancel()
  }

  return(
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}>
        <DialogTitle>Renommer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Nom"
                type="text"
                defaultValue={props.name}
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={() => confirm()} color="secondary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

RenameList.defaultProps = {
  label: ""
}

export default RenameList