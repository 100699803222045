import { Button, Dialog, DialogActions, DialogContent,  DialogTitle, Grid, TextField } from "@material-ui/core"
import React, { useState } from "react"

const Invite = (props) => {
  const [email, setEmail] = useState("")

  const confirm = () => {
    props.handleConfirm(email)
    setEmail("")
    props.handleCancel()
  }

  return(
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}>
        <DialogTitle>Invitation</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={() => confirm()} color="secondary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Invite