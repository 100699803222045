import axios from "axios"
import Config from "./Config"

const authorization = (localStorage.getItem("token") !== null) ? `Bearer ${localStorage.getItem("token")}` : null
const headers = {
  "Content-Type": "application/json",
  authorization
}
const burl = Config.back

const API = {
  parseJwt: () => {
    const token = localStorage.token
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  },

  login: (email, password) => {
    return axios.post(`${burl}/user/login`, {email, password}, {headers})
  },

  signup: (send) => {
    return axios.post(`${burl}/user/signup`, send, { headers })
  },

  forgot: (email) => {
    return axios.post(`${burl}/user/forgot`, {email}, { headers })
  },

  confirm: async (token) => {
    return axios.get(`${burl}/user/confirm/${token}`, { headers })
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error
      })
  },

  listAllStats: () => {
    return axios.get(`${burl}/list/allstats`, {headers})
  },

  isAuth: () => {
    if (localStorage.getItem("token") === null) {
      return false
    } else {
      return true
    }
  },

  reset: (token, password) => {
    return axios.post(`${burl}/user/reset`, {token, password}, {headers})
  },

  logout: () => {
    localStorage.clear()
  },
 
  createList: (label) => {
    return axios.post(`${burl}/list/create`, {label}, {headers})
  },

  changeList: (id) => {
    return axios.post(`${burl}/list/change`, {id}, {headers})
  },
  
  getList: (id) => {
    return axios.get(`${burl}/list/get/${id}`, {headers})
  },
  
  getActuel: () => {
    return axios.get(`${burl}/list/actuel`, {headers})
  },
  
  getItems: (id) => {
    return axios.get(`${burl}/list/items/${id}`, {headers})
  },

  addItem: (list, params) => {
    return axios.post(`${burl}/list/additem`, {list, item: params}, {headers})
  },

  majItem: (params) => {
    return axios.post(`${burl}/list/majitem`, {item: params}, {headers})
  },

  renameList: (id, name) => {
    return axios.post(`${burl}/list/rename`, {id, name}, {headers})
  },

  getUsers: (id) => {
    return axios.get(`${burl}/list/users/${id}`, {headers})
  },

  invite: (list, email) => {
    return axios.post(`${burl}/list/invite`, {list, email}, {headers})
  },

  changeStatus: (item, status) => {
    return axios.post(`${burl}/list/changestatus`, {item, status}, {headers})
  },

  leaveList: () => {
    return axios.post(`${burl}/list/leave`, {}, {headers})
  }
}

export default API