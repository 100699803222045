import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
/* import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox' */
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import API from "../../utils/API"
import { Collapse, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Copyright from '../common/Copyright'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

// Si l'utilisateur est déjà connecté, on le redirige vers sa Home

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Signup = () => {
  const {token} = useParams()
  const [password, setPassword] = useState("")
  const [cpassword, setCpassword] = useState("")
  const [open, setOpen] = React.useState(false)
  const [retour, setRetour] = useState("")

  useEffect(() => {
    if (API.isAuth()) {
      window.location = "/home"
    }
    if (token === undefined) {
      setRetour("Il n'y a pas de token de réinitialisation")
      setOpen(true)
    }
  }, [token])


  const send = async () => {
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    if (!passwordRegex.test(password) || !passwordRegex.test(cpassword)) {
      setRetour("Le mot de passe ne respecte pas la norme de sécurité (8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial)")
      setOpen(true)
      return false
    }
    if (!password || password.length === 0) {
      setRetour("Mot de passe non renseigné")
      setOpen(true)
      return false
    }
    if (!cpassword || cpassword.length === 0) {
      setRetour("Confirmation du mot de passe non renseignée")
      setOpen(true)
      return false
    }
    if (password !== cpassword) {      
      setRetour("Les mots de passe ne sont pas identiques")
      setOpen(true)
      return false
    }
    API.reset(token, password)
      .then(data => {
        if (data.data.token === undefined) {
          setRetour("Le token de réinitialisation n'existe pas ou est expiré")
          setOpen(true)
          //setTimeout(redirect => {window.location = "/"}, 5000)
        } else {
          localStorage.setItem("token", data.data.token)
          window.location = "/home"
        }
      })
      .catch(error => {
        setRetour("Le token de réinitialisation n'existe pas ou est expiré")
        setOpen(true)
        //setTimeout(redirect => {window.location = "/"}, 5000)
      })
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Charge Mentale Manager
        </Typography>
        <Typography component="h2" variant="h7">
          Réinitialisation
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            helperText="8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial (!@#$%^&*)"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="cpassword"
            label="Confirmation"
            type="password"
            id="cpassword"
            value={cpassword}
            onChange={e => setCpassword(e.target.value)}
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={send}
            className={classes.submit}
          >
            Envoyer
          </Button>
        </form>
      </div>
      <Collapse in={open}>
        <Alert 
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {retour}
        </Alert>
      </Collapse>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Signup