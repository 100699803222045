import { Button, Dialog, DialogActions, DialogContent,  DialogContentText,  DialogTitle } from "@material-ui/core"
import React from "react"

const Leave = (props) => {
  const confirm = () => {
    props.handleConfirm()
    props.handleCancel()
  }

  return(
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Voulez-vous vraiment quitter la liste "{props.name}" ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            Non
          </Button>
          <Button onClick={() => confirm()} color="secondary">
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Leave