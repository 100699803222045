import { Avatar, Typography, Box, Button, Checkbox, Divider, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from "react"
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SetTask from "./SetTask";
import API from "../../utils/API"
import Functions from "../../utils/Functions"
import Skeleton from "@material-ui/lab/Skeleton";
import socketIOClient from "socket.io-client";
import Config from "../../utils/Config"


const socket = socketIOClient(Config.back, {secure: true, forceNew: true});
//export const socket = socketIOClient('ws://localhost:8080');

const useStyles = makeStyles((theme) => ({
  fabButton: {
    position: 'fixed',
    zIndex: 1,
    bottom: 15,
    right: 15,
    margin: '0 auto',
  },
  checked: {
    textDecoration: 'line-through',
    fontStyle: "italic"
  },
  allDone: {
    padding: theme.spacing(3, 2),
    height: window.innerHeight-100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  box: {
    padding: theme.spacing(3),
    textAlign: "center"
  },
  emptyIcon: {    
    textAlign: 'center',
    AlignItems: 'center',
    fontSize: "80"
  }
}))

const MainTask = (props) => {

  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [openAdd, setOpenAdd] = useState(false)
  const [openModify, setOpenModify] = useState(false)
  const [item, setItem] = useState({
    _id: "",
    label: "",
    quantity: 0,
    unit: "",
    user: {
      fname: API.parseJwt().fname,
      lname: API.parseJwt().lname
    }
  })

  const modify = (id) => {
    for (const i of items) {
      if (i._id === id) {
        setItem({
          _id: i._id,
          label: i.label,
          quantity: i.quantity,
          unit: i.unit
        })
        setOpenModify(true)
      }
    }
  }

  const addItem = (params) => {
    const {label, quantity, unit} = params
    API.addItem(props.list, params)
    .then(resp => {
      setItems([...items, {_id: resp.data._id, label, quantity, unit, status: 0,
        user: {
          fname: API.parseJwt().fname,
          lname: API.parseJwt().lname
        }}])
        socket.emit("item", props.list)
    })
  }

  const majItem = (params) => {
    API.majItem(params)
      .then(resp => {
        let newItems = [...items]
        for (const i in newItems) {
          if (newItems[i]._id === params._id) {
            newItems[i].label = params.label
            newItems[i].quantity = params.quantity
            newItems[i].unit = params.unit
            setItems(newItems)
            console.log(newItems);
            setItem({
              _id: "",
              label: "",
              quantity: 0,
              unit: "",
              user: {
                fname: API.parseJwt().fname,
                lname: API.parseJwt().lname
              }
            })
            socket.emit("item", props.list)
            setOpenModify(false)
          }
        }
        
      })
  }

  useEffect(() => {
    socket.on(props.list, () => {
      setIsLoading(true)
  
      if(props.list !== "") {
        const getItems = async () => {
          const result = await API.getItems(props.list)
          setItems(result.data.data)
        } 
        getItems()
      } else {
        setItems([])
      }
      setIsLoading(false)
    })
  }, [props.list])

  useEffect(() => {
    setIsLoading(true)

    if(props.list !== "") {
      const getItems = async () => {
        const result = await API.getItems(props.list)
        setItems(result.data.data)
      } 
      getItems()
    } else {
      setItems([])
    }
    setIsLoading(false)
  }, [props.list])
  
  const changeStatus = (_id, status) => {
    let newItems = items.map(item => {
      if (item._id === _id) {
        item.status = status
        API.changeStatus(item._id, status)
        socket.emit("item", props.list)
      }
      return item
    })
    setItems(newItems)
  }
  
  const classes = useStyles();

  return (
    <>
      <div className={classes.paper}>
        <Fab color="secondary" onClick={() => setOpenAdd(true)} className={classes.fabButton}>
          <AddIcon />
        </Fab>      
      </div>
        {
          items.filter(item => item.status !== 2).length === 0 ? (
            <Paper className={classes.allDone}>
              <Box className={classes.box}>
                <Typography align="center"><DoneAllIcon style={{ fontSize: 250, margin: "auto" }} className={classes.emptyIcon}/></Typography>
                <Typography variant="h4" align="center">Il n'y a rien ici</Typography>
                <br />
                <Button variant="contained" size="large" align="center" color="primary" onClick={() => setOpenAdd(true)} >
                  <AddIcon /> Ajouter
                </Button>
              </Box>
            </Paper>
          ) : (
            <Paper>
            <List dense>
            {items.filter(item => item.status === 0).map(item => (
              <ListItem button key={item._id}>
                <ListItemAvatar><Avatar title={item.user.fname+" "+item.user.lname} alt={"Avatar "+item.user.fname+"+"+item.user.lname} src={Functions.linkAvatar(item.user.fname, item.user.lname)} /></ListItemAvatar> 
                <ListItemText onClick={() => modify(item._id)} primary={item.label} secondary={(item.quantity>0) ? item.quantity+" "+item.unit: item.unit} />
                <ListItemIcon>
                  <IconButton aria-label="delete" onClick={()=>changeStatus(item._id, 2)}>
                    <DeleteIcon color="primary"/>
                  </IconButton>
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={item.status}
                    tabIndex={-1}
                    disableRipple
                    name={item._id}
                    onClick={e => changeStatus(e.target.name, 1)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            </List>
    
            { (items.filter(item => item.status === 1).length !== 0 && items.filter(item => item.status !== 0).length === 0 ) &&
              <Divider variant="middle" />
            }
            <List>
            {isLoading &&
              <>
                <ListItem>
                  <ListItemAvatar><Skeleton variant="circle" width={40} height={40} /></ListItemAvatar>
                  <ListItemText><Skeleton variant="text" width="30%" /></ListItemText>
                  <ListItemIcon>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemSecondaryAction>
                    <Checkbox
                      disabled
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar><Skeleton variant="circle" width={40} height={40} /></ListItemAvatar>
                  <ListItemText><Skeleton variant="text" width="35%" /><Skeleton variant="text" width="20%" /></ListItemText>
                  <ListItemIcon>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemSecondaryAction>
                    <Checkbox
                      disabled
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar><Skeleton variant="circle" width={40} height={40} /></ListItemAvatar>
                  <ListItemText><Skeleton variant="text" width="25%" /><Skeleton variant="text" width="30%" /></ListItemText>
                  <ListItemIcon>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemSecondaryAction>
                    <Checkbox
                      disabled
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            }
    
            {items.filter(item => item.status === 1).map(item => (
              <ListItem button key={item._id}>
                <ListItemAvatar><Avatar title={item.user.fname+" "+item.user.lname} alt={"Avatar "+item.user.fname+"+"+item.user.lname} src={Functions.linkAvatar(item.user.fname, item.user.lname)} /></ListItemAvatar> 
                <ListItemText onClick={() => modify(item._id)} primary={item.label} className={classes.checked} secondary={(item.quantity>0) ? item.quantity+" "+item.unit: item.unit}></ListItemText>
                <ListItemIcon>
                  <IconButton aria-label="delete" onClick={()=>changeStatus(item._id, 2)}>
                    <DeleteIcon color="primary" />
                  </IconButton>
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={item.status}
                    tabIndex={-1}
                    disableRipple
                    name={item._id}
                    onClick={e => changeStatus(e.target.name, 0)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            </List>
          </Paper>
          )
        }
      <SetTask open={openAdd} handleConfirm={addItem} handleCancel={() => setOpenAdd(false)} />
      <SetTask open={openModify} handleConfirm={majItem} _id={item._id} label={item.label} quantity={item.quantity} unit={item.unit} handleCancel={() => setOpenModify(false)} />      
    </>
  )
}

export default MainTask