import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core"
import React, { useState } from "react"

const AddList = (props) => {

  const [label, setLabel] = useState(props.label)

  const confirm = () => {
    props.handleConfirm(label)
    setLabel("")
  }

  return(
    <div>
      <Dialog open={props.open} onClose={() => props.handleCancel(false)}>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Nom"
            type="text"
            value={label}
            onChange={e => setLabel(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.handleCancel(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={()=>confirm()} color="secondary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddList