import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
/* import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox' */
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import API from "../../utils/API"
import { Collapse, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Copyright from '../common/Copyright'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

// Si l'utilisateur est déjà connecté, on le redirige vers sa Home

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Signup = (props) => {

  const {email} = useParams()
  useEffect(() => {
    if (API.isAuth()) {
      window.location = "/home"
    }
  })

  const [ credentials, setCredentials ] = useState({
    email: "",
    fname: "",
    lname: "",
    password: "",
    cpassword: ""
  })

  useEffect(()=>{
    if (email) {
      setCredentials({
        email,
        fname: "",
        lname: "",
        password: "",
        cpassword: ""
      })
    }
  }, [email])

  const [severity, setSeverity] = useState("error")
  const [open, setOpen] = React.useState(false)
  const [retour, setRetour] = useState("")

  const send = async () => {
    const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$")
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")

    if (!mailRegex.test(credentials.email)) {
      setSeverity("error") 
      setRetour("L'adresse mail n'est pas valide")
      setOpen(true)
      return false
    }
    if (!passwordRegex.test(credentials.password) || !passwordRegex.test(credentials.cpassword)) {
      setSeverity("error") 
      setRetour("Le mot de passe ne respecte pas la norme de sécurité (8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial)")
      setOpen(true)
      return false
    }
    if (!credentials.email || credentials.email.length === 0) {
      setSeverity("error") 
      setRetour("Email non renseigné")
      setOpen(true)
      return false
    }
    if (!credentials.fname || credentials.fname.length === 0) {
      setSeverity("error") 
      setRetour("Prénom non renseigné")
      setOpen(true)
      return false
    }
    if (!credentials.lname || credentials.lname.length === 0) {
      setSeverity("error") 
      setRetour("Nom non renseigné")
      setOpen(true)
      return false
    }
    if (!credentials.password || credentials.password.length === 0) {
      setSeverity("error") 
      setRetour("Mot de passe non renseigné")
      setOpen(true)
      return false
    }
    if (!credentials.cpassword || credentials.cpassword.length === 0) {
      setSeverity("error") 
      setRetour("Confirmation du mot de passe non renseignée")
      setOpen(true)
      return false
    }
    if (credentials.password !== credentials.cpassword) {     
      setSeverity("error") 
      setRetour("Les mots de passe ne sont pas identiques")
      setOpen(true)
      return false
    }
    API.signup({email: credentials.email, fname: credentials.fname, lname: credentials.lname, password: credentials.password})
      .then(data => {
        setSeverity("success")
        setRetour("Un email de validation du compte a été envoyé à l'adresse "+credentials.email)
        setOpen(true)
        setTimeout(() => {window.location = "/home"}, 5000)
        return true
      })
      .catch(error => {
        setSeverity("error") 
        setRetour("Erreur inconnue")
        setOpen(true)
        return false
      })
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Charge Mentale Manager
        </Typography>
        <Typography component="h2" variant="h7">
          Inscription
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoComplete="fname"
                name="fname"
                variant="outlined"
                required
                fullWidth
                id="fname"
                label="Prénom"
                value={credentials.fname}
                onChange={e => setCredentials({...credentials, [e.target.name]: e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lname"
                label="Nom"
                name="lname"
                value={credentials.lname}
                onChange={e => setCredentials({...credentials, [e.target.name]: e.target.value})}
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={credentials.email}
                disabled={(email) ? true : false}
                onChange={e => setCredentials({...credentials, [e.target.name]: e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                helperText="8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial"
                value={credentials.password}
                onChange={e => setCredentials({...credentials, [e.target.name]: e.target.value})}
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="cpassword"
                label="Confirmation"
                type="password"
                id="cpassword"
                value={credentials.cpassword}
                onChange={e => setCredentials({...credentials, [e.target.name]: e.target.value})}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={send}
            className={classes.submit}
          >
            Envoyer
          </Button>
        </form>
      </div>
      <Collapse in={open}>
        <Alert 
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {retour}
        </Alert>
      </Collapse>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Signup