import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import API from "../../utils/API"
import Functions from "../../utils/Functions"

const Users = (props) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    if(props.list !== "") {
      const getUsers = async () => {
        const result = await API.getUsers(props.list)
        setUsers(result.data)
      } 
      getUsers()
    } else {
      setUsers([])
    }
  }, [props.list])
  
  return (
    <>
      {users.map((user, i) => (
        <ListItem button key={i}>
          <ListItemAvatar ><Avatar title={user.fname+" "+user.lname} alt={"Avatar "+user.fname+"+"+user.lname} src={Functions.linkAvatar(user.fname, user.lname)} /></ListItemAvatar> 
          <ListItemText primary={user.fname+' '+user.lname} secondary={user.email}></ListItemText>
        </ListItem>

      ))}
    </>
  )
}

export default Users