import React from 'react'
import { Route, Switch } from "react-router-dom"
import Login from "./components/pages/Login"
import Signup from "./components/pages/Signup"
import Home from "./components/pages/Home"
import Forgot from "./components/pages/Forgot"
import Confirm from "./components/pages/Confirm"
import Reset from "./components/pages/Reset"
import PrivateRoute from "./components/PrivateRoute"

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/signup/:email" component={Signup} />
          <Route path="/signup" component={Signup} />
          <Route path="/reset/:token" component={Reset} />
          <Route path="/forgot" component={Forgot} />
          <Route path="/confirm/:token" component={Confirm} />
          <PrivateRoute path="/home"component={Home} />
        </Switch>
      </div>
    </div>
  )
}

export default App