import { Button, Dialog, DialogActions, DialogContent,  DialogTitle, Grid, makeStyles, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1)
  },
}))

const SetTask = (props) => {
  useEffect(() => {
    setLabel(props.label)
    setQuantity(props.quantity)
    setUnit(props.unit)
    set_Id(props._id)
  }, [props])
  const classes = useStyles();

  const [label, setLabel] = useState(props.label)
  const [quantity, setQuantity] = useState(props.quantity)
  const [unit, setUnit] = useState(props.unit)
  const [_id, set_Id] = useState(props._id)

  const confirm = () => {
    props.handleConfirm({label, quantity, unit, _id})
    setLabel("")
    setQuantity(0)
    setUnit("")
    set_Id("")
    props.handleCancel()
  }

  return(
    <div>
      <Dialog open={props.open} onClose={props.handleCancel}>
        { props._id === "" ?
          <DialogTitle>Ajout</DialogTitle>
          :
          <DialogTitle>Modification</DialogTitle>
        }
        <DialogContent>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Nom"
                type="text"
                value={label}
                onChange={e => setLabel(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid xs={3} className={classes.root}>
              <TextField
                margin="dense"
                id="name"
                label="Quantité"
                type="number"
                value={quantity}
                onChange={e => setQuantity(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid xs={7} className={classes.root}>
              <TextField
                margin="dense"
                id="name"
                label="Détail"
                value={unit}
                onChange={e => setUnit(e.target.value)}
                type="text"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={() => confirm()} color="secondary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

SetTask.defaultProps = {
  _id: "",
  label: "",
  quantity: 0,
  unit: ""
}

export default SetTask