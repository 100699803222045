import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import API from "../../utils/API"
import { Collapse, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Copyright from '../common/Copyright'

// Si l'utilisateur est déjà connecté, on le redirige vers sa Home


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 0
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Login = () => {
  useEffect(() => {
    if (API.isAuth()) {
      window.location = "/home"
    }
  })

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [open, setOpen] = React.useState(false)
  const [retour, setRetour] = useState("")

  const send = async () => {
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    if (!passwordRegex.test(password)) {
      setRetour("Le mot de passe ne respecte pas la norme de sécurité (8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial)")
      setOpen(true)
      return false
    }
    if (!email || email.length === 0) {
      setRetour("Email non renseigné")
      setOpen(true)
      return false
    }
    if (!password || password.length === 0) {
      setRetour("Mot de passe non renseigné")
      setOpen(true)
      return false
    }    
    API.login(email, password)
      .then(data => {
        if (data.status === 204) {
          setRetour("Email ou mot de passe incorrect")
          setOpen(true)
          return false
        }
        if (data.status === 200) {
          localStorage.setItem("token", data.data.token)
          window.location = "/home"
          return true
        }
      })
      .catch(error => {
        setRetour("Erreur inconnue ("+error+")")
        setOpen(true)
        return false
      })
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Charge Mentale Manager
        </Typography>
        <Typography component="h2" variant="h7">
          Connexion
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="user"
            label="Email"
            name="user"
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={send}
            className={classes.submit}
          >
            Envoyer
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot" variant="body2">
                Mot de passe oublié
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                Pas encore de compte ?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <br />
      <Collapse in={open}>
        <Alert 
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {retour}
        </Alert>
      </Collapse>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Login