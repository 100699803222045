import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import API from "../../utils/API"
import { Collapse, Link } from '@material-ui/core'
import Copyright from '../common/Copyright'

// Si l'utilisateur est déjà connecté, on le redirige vers sa Home

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}))

const Confirm = () => {
  const {token} = useParams()
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState("")
  const [retour, setRetour] = useState("")

  useEffect(() => {
    if (API.isAuth()) {
      window.location = "/home"
    }
    API.confirm(token)
      .then(data => {
        if (data.token === undefined) {
          setSeverity("error")
          setRetour("Le token d'activation n'existe pas ou est expiré")
          setOpen(true)
          setTimeout(redirect => {window.location = "/"}, 5000)
        } else {
          console.log(data.token);
          localStorage.setItem("token", data.token)
          setSeverity("success")
          setRetour("Votre compte a bien été activé. Vous allez être redirigé sur la page d'accueil")
          setOpen(true)
          setTimeout(redirect => {window.location = "/home"}, 5000)
        }
    })
  }, [token])
  

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Charge Mentale Manager
        </Typography>
        <Typography component="h2" variant="h7">
          Confirmation création
        </Typography>
      </div>
      <br />
      <Collapse in={open}>
        <Alert 
          severity={severity}
        >
          {retour}
        </Alert>
      </Collapse>
      <br />
      { (severity === "success") && 
        <Link href="/home" variant="body2">
          Je suis pressé d'y aller
        </Link>
      }
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Confirm