import React from "react"
import API from "../utils/API.js"
import { Route, Redirect } from "react-router-dom"

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!API.isAuth()) {
        return <Redirect to="/" />
      } else {
        return <Component {...props} />
      }
    }}
  />
)

export default PrivateRoute