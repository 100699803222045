import React, {useEffect, useState} from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'
import API from "../../utils/API"
import { CssBaseline, Divider, AppBar, IconButton, ListSubheader, SwipeableDrawer, Toolbar, Typography, List, ListItem, ListItemIcon, ListItemText, Badge, Collapse } from '@material-ui/core'
import MainTask from '../tasks/MainTask'
import Users from '../tasks/Users'
import AddIcon from '@material-ui/icons/Add'
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddList from '../lists/AddList'
import EditIcon from '@material-ui/icons/Edit';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import RenameList from '../tasks/RenameList'
import Invite from '../tasks/Invite'
import Leave from '../tasks/Leave'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 50
  },
}))

const Home = () => {
  const [allstats, setAllstats] = useState([])
  const [actuel, setActuel] = useState("")
  const [nameList, setNameList] = useState("")
  const [actif, setActif] = useState("list")
  const [opennewlist, setOpennewlist] = useState(false)
  const [openRename, setOpenRename ] = useState(false)
  const [openInvite, setOpenInvite] = useState(false)
  const [openLeave, setOpenLeave ] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [severityAlert, setSeverityAlert] = useState("success")
  const [textAlert, setTextAlert] = useState("")
  const [mobileOpen, setMobileOpen] = useState({
    left: false,
    right: false
  })

  const getActuel = async () => {
    const result = await API.getActuel()
    setActuel(result.data._id)
    setNameList(result.data.label)
    changeList(result.data.label)
  }

  useEffect(() => {
    getActuel()
  })
  
  const fetchLists = async () => {
    const result = await API.listAllStats()
    setAllstats(result.data.data)
  }

  useEffect(() => {
    fetchLists()
  }, [])


  const logout = () => {
    API.logout()
    window.location = "/"
  }
  
  const classes = useStyles();
  
  const toggleDrawer = (anchor) => {
    fetchLists()
    setMobileOpen({ ...mobileOpen, [anchor]: !mobileOpen[anchor] });
  }

  const createList = (label) => {
    API.createList(label)
    .then(res => {
      fetchLists()
      changeList(res.data._id)
    })
    .catch(err=>{console.log(err)})
    setOpennewlist(false)
    toggleDrawer("right")
  }

  const changeModule = (display) => {
    setActif(display)
    toggleDrawer('left')
  }

  const changeList = async id => {
    await API.changeList(id)
    await getActuel()
    toggleDrawer("right")
  }

  const renameList = (name) => {
    API.renameList(actuel, name)
    setNameList(name)
    setOpenRename(false)
    setSeverityAlert("success") 
    setTextAlert("Liste renommée avec succès")
    setOpenAlert(true)
    toggleDrawer('left')
  }

  const invite = (email) => {    
    const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$") 
    if (!mailRegex.test(email)) {
      setSeverityAlert("error") 
      setTextAlert("L'adresse mail n'est pas valide")
      setOpenAlert(true)
      return false
    }
    API.invite(actuel, email)
    setSeverityAlert("success") 
    setTextAlert("Invitation envoyée à "+email)
    setOpenAlert(true)
    toggleDrawer("left")
  }

  const leave = async () => {
    toggleDrawer("left")
    await API.leaveList()
    setSeverityAlert("success")
    setTextAlert('La liste "'+nameList+'" a été quittée avec succès')
    getActuel()
    setOpenAlert(true)
  }

  const leftDrawer = (
    <div>
      <List>
        <ListSubheader component="div" id="nested-list-subheader">
          Paramètres
        </ListSubheader>
      </List>
      <List>
        <ListItem button onClick={() => setOpenRename(true)}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText primary="Renommer" />
        </ListItem>
        <ListItem button disabled={actif === "list" ? true : false} onClick={() => changeModule("list")}>
          <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
          <ListItemText primary="Liste" />
        </ListItem>
        <ListItem button disabled={actif === "users" ? true : false} onClick={() => changeModule("users")}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary="Membres" />
        </ListItem>
        <ListItem button onClick={() => setOpenInvite(true)}>
          <ListItemIcon><PersonAddIcon /></ListItemIcon>
          <ListItemText primary="Inviter" />
        </ListItem>
        <ListItem button onClick={() => setOpenLeave(true)}>
          <ListItemIcon><ExitToAppIcon/></ListItemIcon>
          <ListItemText primary="Quitter la liste" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={logout}>
          <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
          <ListItemText primary="Déconnexion" />
        </ListItem>
      </List>
    </div>
  )

  const rightDrawer = (
    <div>
      <List>
        <ListSubheader component="div" id="nested-list-subheader">
          Listes
        </ListSubheader>  
        <ListItem button onClick={() => setOpennewlist(true)}>
          <ListItemIcon><AddIcon /></ListItemIcon>
          <ListItemText primary="Créer une liste" />
        </ListItem>
      </List>
      <Divider />
      <List>
        {allstats.map(list => (
          <ListItem button key={list._id} onClick={() => changeList(list._id)}>
            <ListItemText primary={list.label} />
            <Badge color="secondary" badgeContent={list.items} />
          </ListItem>
        ))}
      </List>
    </div>
  )
  
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open left drawer"
              edge="start"
              onClick={() => toggleDrawer("left")}
            >
            <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.title}>
              CMM - {nameList}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              edge="end"
              onClick={() => toggleDrawer("right")}
            >
            <MoreVertIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav aria-label="left menu">
          <SwipeableDrawer
            anchor="left"
            open={mobileOpen["left"]}
            onClose={() => toggleDrawer("left")}
            onOpen={() => toggleDrawer("left")}
          >
            {leftDrawer}
          </SwipeableDrawer>
        </nav>
        <nav aria-label="right menu">
          <SwipeableDrawer
            anchor="right"
            open={mobileOpen["right"]}
            onClose={() => toggleDrawer("right")}
            onOpen={() => toggleDrawer("right")}
          >
            {rightDrawer}
          </SwipeableDrawer>
        </nav>
        <main className={classes.content}>
        <Collapse in={openAlert}>
          <Alert 
            severity={severityAlert}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {textAlert}
          </Alert>
        </Collapse>
          {actif === "list" &&
            <MainTask list={actuel}/>
          }
          {actif === "users" &&
            <Users list={actuel}/>
          }
        </main>
      </div>
      <AddList open={opennewlist} handleConfirm={createList} handleCancel={setOpennewlist} name=""/>
      <RenameList open={openRename} handleConfirm={renameList} handleCancel={() => setOpenRename(false)} name={nameList}/>
      <Invite open={openInvite} handleConfirm={invite} handleCancel={() => setOpenInvite(false)} name={nameList}/>
      <Leave open={openLeave} handleConfirm={leave} handleCancel={() => setOpenLeave(false)} name={nameList}/>
    </>
  )
}

export default Home