import React, {useEffect, useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Alert from '@material-ui/lab/Alert'
import API from "../../utils/API"
import { Collapse, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import Copyright from '../common/Copyright'

// Si l'utilisateur est déjà connecté, on le redirige vers sa Home

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Forgot = () => {
  useEffect(() => {
    if (API.isAuth()) {
      window.location = "/home"
    }
  })

  const [email, setEmail] = useState("")
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState("")
  const [retour, setRetour] = useState("")

  const send = async () => {
    const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$")
    if (!mailRegex.test(email)) {
      setRetour("L'adresse mail n'est pas valide")
      setSeverity("error")
      setOpen(true)
      return false
    }
    if (!email || email.length === 0) {
      setRetour("Email non renseigné")
      setSeverity("error")
      setOpen(true)
      return false
    }
    API.forgot(email)
      .then(data => {
        if (data.status === 200) {
          setSeverity("success")
          setRetour("Si un compte existe à cette adresse, vous allez recevoir un mail vous permettant de réinitialiser votre mot de passe.")
          setOpen(true)
          return true
        }
      })
      .catch(error => {
        setSeverity("error")
        setRetour("Erreur inconnue ("+error+")")
        setOpen(true)
        return false
      })
  }

  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Charge Mentale Manager
        </Typography>
        <Typography component="h2" variant="h7">
          Mot de passe oublié
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="email"
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={send}
            className={classes.submit}
          >
            Envoyer
          </Button>
        </form>
      </div>
      <Collapse in={open}>
        <Alert 
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {retour}
        </Alert>
      </Collapse>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default Forgot